import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader
} from '@material-tailwind/react'
import { Group, Info, KeyboardArrowRight } from '@mui/icons-material'
import moment from 'moment-timezone'
import { useNavigate } from 'react-router-dom'
import Contents from '../../Components/Contents'
import { Cookies, Spinner, VoteIcon } from '../../utils'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { EngineersSelector, IsVotedSelector } from '../../recoil/selectors'
import { IReward } from '../../interfaces/app.interfaces'
import { MenuActive } from '../../recoil/atom'

const Dashboard = () => {
  const navigate = useNavigate()
  const setMenuActive = useSetRecoilState(MenuActive)
  const engineers = useRecoilValueLoadable(EngineersSelector)
  const isVoted = useRecoilValueLoadable(IsVotedSelector)
  const [votedList, setVotedList] = useState<IReward[]>([])
  const [voted, setVoted] = useState<string | undefined>(
    'Sepertinya anda belum melakukan vote periode!'
  )
  const dateReward = new Date()
  dateReward.setMonth(dateReward.getMonth() - 1)
  const Period = moment(dateReward).format('MMMM YYYY')
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(!open)

  useEffect(() => {
    if (isVoted.state === 'hasValue') {
      const p = isVoted.contents
      if (p[0].pk == 0 && p[1].pk == 0 && p[2].pk == 0) {
        setVoted('Sepertinya anda belum melakukan vote periode ')
      } else if (p[0].pk > 0 && p[1].pk > 0 && p[2].pk > 0) {
        setVoted(undefined)
      } else {
        setVoted('Sepertinya vote anda belum selesai di periode ')
      }
      if (engineers.state === 'hasValue') {
        setVotedList(
          p.map(r => {
            return {
              model: r.model,
              pk: r.pk,
              fields: {
                date: r?.fields?.date,
                score: r?.fields?.score,
                vote_by: r?.fields?.vote_by,
                engineer_name: r?.fields?.engineer_name,
                engineer_email: r?.fields?.engineer_email,
                engineer_location: r?.fields?.engineer_location,
                period: r?.fields?.period
              }
            }
          })
        )
        setLoading(false)
      }
    }
  }, [isVoted, engineers])

  const ViewMyVote = () => {
    setOpen(!open)
  }
  const gotoVoting = () => {
    setMenuActive("/voting")
    navigate('/voting')
  }
  return (
    <Contents>
      <div className='flex'>
        <h4 className='text-xl mb-5'>
          WELCOME {Cookies.Get('full_name').value?.toUpperCase()}...
        </h4>
      </div>
      <div className='w-full mb-28'>
        <Card className='w-[600px]'>
          <CardBody>
            <div className='flex space-x-4'>
              <div>
                <Info fontSize='large' className='!text-6xl' color='disabled' />
              </div>
              <div className='relative'>
                {loading ? (
                  <Spinner />
                ) : (
                  <div>
                    <div>
                      {voted === undefined ? (
                        <div>
                          <p>
                            Terima kasih, telah berpartisipasi Vote Employee
                            Rewards Service Poin periode{' '}
                            <span className='font-bold'>{Period}</span>
                          </p>
                          <div className='text-right'>
                            <Button
                              onClick={ViewMyVote}
                              size='sm'
                              color='green'
                            >
                              Lihat Vote Anda!
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <p>
                          {voted} <span className='font-bold'>{Period}</span>
                        </p>
                      )}
                    </div>
                    {voted !== undefined && (
                      <Button
                        className='mt-5 flex justify-center items-center'
                        onClick={() => gotoVoting()}
                        size='sm'
                      >
                        <VoteIcon />
                        {' '}Voting Sekarang Yuk!
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>My Voting & Score</DialogHeader>
        <DialogBody divider className='!px-0'>
          <table className='w-full'>
            <thead>
              <tr className='border-b'>
                <th className='text-left px-2'>Engineer</th>
                <th className='text-left px-2'>Period</th>
                <th className='text-left px-2'>Score</th>
              </tr>
            </thead>
            <tbody>
              {votedList.map((v, i) => {
                return (
                  <tr className='border-b' key={i}>
                    <td className="px-2">
                      <div className='flex flex-col'>
                        <p className='font-medium'>{v.fields.engineer_name}</p>
                        <small>{v.fields.engineer_email}</small>
                        <small>{v.fields.engineer_location}</small>
                        <small>voting date: {moment(v.fields.date).format("YYYY-MM-DD HH:mm:ss")}</small>
                      </div>
                    </td>
                    <td className="px-2">{v.fields.period}</td>
                    <td className="font-bold px-2">{v.fields.score}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </DialogBody>
        <DialogFooter>
          <Button
            variant='text'
            color='red'
            onClick={handleOpen}
            className='mr-1'
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Contents>
  )
}

export default Dashboard
