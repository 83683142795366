import React, { useEffect, useState } from 'react'
import Contents from '../../Components/Contents'

import SelectCustom, { IOptions } from '../../Components/Atoms/SelectCustom'
import moment from 'moment-timezone'
import {
  Typography,
  Card,
  CardBody,
  Button,
  CardHeader,
  CardFooter,
  Textarea,
  Input
} from '@material-tailwind/react'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { EngineersSelector } from '../../recoil/selectors'
import { ArraySearch, Cookies, Csp, Request, Spinner } from '../../utils'
import { CheckCircle, Info, ArrowRight } from '@mui/icons-material'
import { useConfirmDlg } from '../../Components/Atoms/Dialog/Confirm'
import { IsVotedSelector } from '../../recoil/selectors'
import { toast } from 'react-toastify'
import { Axios, AxiosError } from 'axios'
// import { useNavigate } from 'react-router-dom'

interface Pilihan extends IOptions {
  score?: number
}
interface PilihResponse {
  pilih: 'pilih1' | 'pilih2' | 'pilih3'
  status: boolean
  message: string
}
interface errorMessage {
  message: string
  error: boolean | undefined
}

const ex: string[] = String(process.env.REACT_APP_EX_ENGINEER).split(';')
const Vote = () => {
  const { confirm } = useConfirmDlg()
  const defaultValue: IOptions = {
    label: '',
    value: ''
  }
  const voted = useRecoilValueLoadable(IsVotedSelector)
  const voteRefresh = useRecoilRefresher_UNSTABLE(IsVotedSelector)
  const [errorValue1, setErrorValue1] = useState<errorMessage | undefined>()
  const [errorValue2, setErrorValue2] = useState<errorMessage | undefined>()
  const [errorValue3, setErrorValue3] = useState<errorMessage | undefined>()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [pilih1, setPilih1] = useState<Pilihan>(defaultValue)
  const [pilih2, setPilih2] = useState<Pilihan>(defaultValue)
  const [pilih3, setPilih3] = useState<Pilihan>(defaultValue)

  const [reason1, setReason1] = useState('')
  const [reason2, setReason2] = useState('')
  const [reason3, setReason3] = useState('')
  // const [pilihResponse, setPilihResponse] = useState<PilihResponse[]>([])
  const engineers = useRecoilValueLoadable(EngineersSelector)
  const [dataSet, setData] = useState<IOptions[]>([])
  const dateReward = new Date()
  dateReward.setMonth(dateReward.getMonth() - 1)
  const Period = moment(dateReward).format('MMMM YYYY')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // console.log("=> EX: ",String(process.env.REACT_APP_EX_ENGINEER))
    setLoading(true)
    if (engineers.state === 'hasValue') {
      const data = Promise.all(
        engineers.contents
          .filter(v => v.engineer_email !== Cookies.Get('email').value)
          .map(val => {
            return {
              label: val.engineer_name,
              value: val.engineer_email
            } as IOptions
          })
      )
      data.then(v => {
        let csps = ArraySearch<IOptions, string>(v, ex, (a, b) => a.value === b)
        csps.sort((a, b) => (a.label < b.label ? -1 : 1))
        if (voted.state === 'hasValue') {
          const inUse: string[] = []
          voted.contents.map((m, i) => {
            if (m.pk > 0) {
              inUse.push(m.fields.engineer_email)
            }
            if (i === 0 && m.pk > 0) {
              setPilih1({
                label: m.fields.engineer_name,
                value: m.fields.engineer_email
              })
            } else if (i === 1 && m.pk > 0) {
              setPilih2({
                label: m.fields.engineer_name,
                value: m.fields.engineer_email
              })
            } else if (i === 2 && m.pk > 0) {
              setPilih3({
                label: m.fields.engineer_name,
                value: m.fields.engineer_email
              })
            }
          })
          const fdata = ArraySearch<IOptions, string>(csps, inUse, (a, b) => {
            console.log(a.value, b, ' => ', a.value === b)
            return a.value === b
          })
          setData(fdata)
          setLoading(false)
        }
      })
    }
  }, [engineers, errorValue1, errorValue2, errorValue3, voted])

  // const checkPilihanDouble = (v: IOptions) => {
  //   const semuaPilihan = [pilih1, pilih2, pilih3]
  //   return semuaPilihan.filter(s => s.value == v.value).length > 0
  // }

  const onChange = (val: IOptions, score: number) => {
    // console.log(val, checkPilihanDouble(val))
    // setData(dataSet.filter(v => v.value !== val.value))
    if (score === 5) {
      setErrorValue1(undefined)
      setPilih1({ ...val, score: 5 })
    } else if (score === 3) {
      setErrorValue2(undefined)
      setPilih2({ ...val, score: 3 })
    } else {
      setErrorValue3(undefined)
      setPilih3({ ...val, score: 1 })
    }
  }
  const clearButtonSelected = (val: Pilihan, p: number) => {
    const sorted = [...dataSet, { value: val.value, label: val.label }]
    sorted.sort((a, b) => (a.label < b.label ? -1 : 1))
    setData(sorted)
    if (p === 1) {
      setErrorValue1(undefined)
      setPilih1(defaultValue)
    }
    if (p === 2) {
      setErrorValue2(undefined)
      setPilih2(defaultValue)
    }
    if (p === 3) {
      setErrorValue3(undefined)
      setPilih3(defaultValue)
    }
  }
  const submitHandle = async () => {
    // if (pilih1.value === '' || pilih2.value === '' || pilih3.value === '' || reason1 ==='' || reason2 === '' || reason3 === '') {
    //   return
    // }
    if (pilih1.value === '' || pilih2.value === '' || pilih3.value === '') {
      return
    }
    await confirm({
      title: 'Confirm Vote',
      message:
        'Voting hanya dilakukan sekali dan tidak dapat diulang lagi. Yakin dengan pilihan anda?'
    })
    setSubmitLoading(true)
    const rewards = [
      {
        period: Period,
        engineer_name: pilih1?.label,
        engineer_email: pilih1?.value,
        vote_by: Cookies.Get('email').value,
        score: 5,
        pilih: 'pilih1',
        reason: reason1
      },
      {
        period: Period,
        engineer_name: pilih2?.label,
        engineer_email: pilih2?.value,
        vote_by: Cookies.Get('email').value,
        score: 3,
        pilih: 'pilih2',
        reason: reason2
      },
      {
        period: Period,
        engineer_name: pilih3?.label,
        engineer_email: pilih3?.value,
        vote_by: Cookies.Get('email').value,
        score: 1,
        pilih: 'pilih3',
        reason: reason3
      }
    ]
    Request<PilihResponse[]>({
      endpoint: '/employee/rewards/',
      method: 'POST',
      data: rewards
    })
      .then(r => {
        // console.log('RES:', r.data)
        const errorFound: boolean[] = []
        r.data.map(v => {
          errorFound.push(!v.status)
          if (v.pilih === 'pilih1')
            setErrorValue1({ error: !v.status, message: v.message })
          if (v.pilih === 'pilih2')
            setErrorValue2({ error: !v.status, message: v.message })
          if (v.pilih === 'pilih3')
            setErrorValue3({ error: !v.status, message: v.message })
          return v
        })
        // setPilihResponse(r.data)
        // if (errorFound.filter(v => v === true).length <= 0) {
        //   toast.success("Voting successfully!")
        // }else{
        //   toast.error("Voting is fail!")
        // }
        voteRefresh()
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          alert(err?.response.data)
        }
        console.log(err)
      })
      .finally(() => setSubmitLoading(false))
  }
  const clearForm = () => {
    setPilih1(defaultValue)
    setPilih2(defaultValue)
    setPilih3(defaultValue)
  }
  return (
    <Contents>
      <div>
        <h4 className='text-xl mb-5'>VOTE</h4>
        <div className='flex space-x-5'>
          <Card className='w-[40%] '>
            <CardBody className='p-5'>
              <div className='mb-5 space-y-3'>
                <p>
                  <Info color='info' fontSize='large' />
                  Vote karyawan terbaik periode{' '}
                  <span className='font-bold'>{Period}</span>
                </p>
                <ul>
                  <li>
                    <ArrowRight fontSize='small' />
                    Score pilihan 1 adalah <span className='font-bold'>5</span>
                  </li>
                  <li>
                    <ArrowRight fontSize='small' />
                    Score pilihan 2 adalah <span className='font-bold'>3</span>
                  </li>
                  <li>
                    <ArrowRight fontSize='small' />
                    Score pilihan 3 adalah <span className='font-bold'>1</span>
                  </li>
                </ul>
                <p>
                  <ArrowRight />
                  Tiap engineer hanya boleh memilih 1 employee diantara 3
                  pilihan pada 1 periode, pilihan yang dilakukan berulang tidak
                  akan di allow oleh program secara otomatis,
                </p>
                <p>
                  <ArrowRight />
                  Reward Employee ini mulai dapat di gunakan pada awal bulan
                  berikutnya selama 10 hari, misalkan reward employee pada
                  periode januari 2023, akan di lakukan vote pada awal februari
                  2023 dan begitu seterusnya!
                </p>
              </div>
            </CardBody>
          </Card>
          <Card className='w-[40%]'>
            <CardHeader
              variant='gradient'
              color='blue'
              className='mb-4 grid h-12 place-items-center'
            >
              <Typography variant='h4' color='white'>
                Vote {Period}
              </Typography>
            </CardHeader>
            <CardBody className='p-5 relative'>
              {loading && (
                <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-blue-gray-100/50 z-50 grid place-items-center'>
                  <div className='flex'>
                    <Spinner size='md' />
                  </div>
                </div>
              )}

              <div>
                <div className='form-group'>
                  <div className='flex flex-row space-x-2 justify-around w-full'>
                    <div className='flex flex-col w-full'>
                      <SelectCustom
                        dataSet={dataSet}
                        value={pilih1}
                        searchBox={true}
                        onChange={e => {
                          onChange(e, 5)
                        }}
                        onClickButtonClear={() =>
                          clearButtonSelected(pilih1!, 1)
                        }
                        label='Pilihan 1'
                        name='pilihan1'
                        bodyClass='w-full'
                        containerClass={`w-full ol`}
                      />
                      {errorValue1 !== undefined && (
                        <div>
                          <CheckCircle
                            fontSize='small'
                            color={errorValue1.error ? 'error' : 'success'}
                          />{' '}
                          <small
                            className={
                              errorValue1.error
                                ? 'text-red-600'
                                : 'text-green-600'
                            }
                          >
                            {errorValue1.message}
                          </small>
                        </div>
                      )}
                    </div>
                    {/* <Input value={reason1} onChange={(e) => setReason1(e.target.value)} label='Reason pilihan 1' size='lg' /> */}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='flex flex-row space-x-2 justify-around w-full'>
                    <div className='flex flex-col w-full'>
                      <SelectCustom
                        dataSet={dataSet}
                        value={pilih2}
                        searchBox={true}
                        onChange={e => {
                          onChange(e, 3)
                        }}
                        onClickButtonClear={() =>
                          clearButtonSelected(pilih2!, 2)
                        }
                        label='Pilihan 2'
                        name='pilihan2'
                        bodyClass='w-full'
                        containerClass={`w-full ol`}
                      />
                      {errorValue2 !== undefined && (
                        <div>
                          <CheckCircle
                            fontSize='small'
                            color={errorValue2.error ? 'error' : 'success'}
                          />{' '}
                          <small
                            className={
                              errorValue2.error
                                ? 'text-red-600'
                                : 'text-green-600'
                            }
                          >
                            {errorValue2.message}
                          </small>
                        </div>
                      )}
                    </div>
                    {/* <Input value={reason2} onChange={(e) => setReason2(e.target.value)} label='Reason pilihan 2' size='lg' /> */}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='flex flex-row space-x-2 justify-around w-full'>
                    <div className='flex flex-col w-full'>
                      <SelectCustom
                        dataSet={dataSet}
                        value={pilih3}
                        searchBox={true}
                        onChange={e => {
                          onChange(e, 1)
                        }}
                        onClickButtonClear={() =>
                          clearButtonSelected(pilih3!, 3)
                        }
                        label='Pilihan 3'
                        name='pilihan3'
                        bodyClass='w-full'
                        containerClass={`w-full ol`}
                      />
                      {errorValue3 !== undefined && (
                        <div>
                          <CheckCircle
                            fontSize='small'
                            color={errorValue3.error ? 'error' : 'success'}
                          />{' '}
                          <small
                            className={
                              errorValue3.error
                                ? 'text-red-600'
                                : 'text-green-600'
                            }
                          >
                            {errorValue3.message}
                          </small>
                        </div>
                      )}
                    </div>
                    {/* <Input value={reason3} onChange={(e) => setReason3(e.target.value)} label='Reason pilihan 3' size='lg' /> */}
                  </div>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Button
                disabled={
                  pilih1.value === '' ||
                  pilih2.value === '' ||
                  pilih3.value === ''
                }
                fullWidth
                onClick={submitHandle}
                variant='gradient'
                color='blue'
              >
                {submitLoading && <Spinner />}
                SUBMIT
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </Contents>
  )
}

export default Vote
