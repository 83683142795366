import { atom } from 'recoil'
import {
  IDialog,
  IEngineers,
  IEngineersResponse,
  IReward
} from '../interfaces/app.interfaces'
import { Months } from '../utils'

// export const ActiveMenu = atom({
//     key: 'menuActiveKey',
//     default:{
//         path: window.location.pathname,
//         title: "Dashboard"
//     }
// })
const date = new Date()
const oldMonth = Months[date.getMonth() - 1]
export const Engineers = atom<IEngineers[]>({
  key: 'engineersKeyAtom',
  default: []
})

export const Period = atom<string>({
  key: 'periodKey',
  default: ''
})

export const RewardsAtom = atom<IReward[]>({
  key: 'rewardAtomKey',
  default: []
})

export const RewardFormPeriodAtom = atom<string>({
  key: 'RewardFormPeriodAtom',
  default: oldMonth  + ' ' + date.getFullYear()
})

export const DialogConfirmAtom = atom<IDialog>({
  key: 'dialogConfirmAtom',
  default: {
    title: 'Delete Confirm',
    noLabel: 'Tidak',
    yesLabel: 'Ya',
    open: false,
    size: 'xs',
    body: 'Are you sure?'
  }
})

export const ResultsResponseAtom = atom<IEngineersResponse<IEngineers>>({
  key: 'resultsResponseAtom',
  default: {
    count: 0,
    page: 1,
    page_size: 5,
    engineer_name: '',
    engineer_email: '',
    engineer_location: '',
    results: []
  }
})

export const IsVoted = atom<IReward[]>({
  key: 'isVoted',
  default: []
})

export const MenuActive = atom({
  key: 'menuActiveKey',
  default: window.location.pathname
})
