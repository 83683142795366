import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import { AccountCircle, KeyboardArrowDown } from '@mui/icons-material'
import React from 'react'
import { Cookies } from '../utils'
import logo from '../Assets/logo.png'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useConfirmDlg } from './Atoms/Dialog/Confirm'

const Header = () => {
  const navigate = useNavigate()
  const { confirm } = useConfirmDlg()

  const LogoutHandle = async () => {
    await confirm({
      title: 'Logout Confirm',
      message: 'Are you sure?'
    })
    Cookies.Del(['access', 'email', 'full_name'])
    toast.success('Logout successfull')
    navigate('/auth')
  }

  return (
    <div className='header-container'>
      <div className='flex justify-between items-center h-14 w-11/12 text-gray-700'>
        <div
          onClick={() => navigate('/')}
          className='pl-5 cursor-pointer flex justify-center items-center space-x-2'
        >
          <img alt='logo' width={'60px'} src={logo} />
          <h1 className='text-xl'>Employee Rewards</h1>
        </div>
        <div className='pr-14 flex space-x-10 items-center'>
          <div className='flex items-center space-x-3'>
            <div>
              <AccountCircle fontSize='large' />
            </div>
            <div className='flex items-center justify-center cursor-pointer'>
              <Menu>
                <MenuHandler>
                  <div className='flex'>
                    <h3 className='text-lg sm:hidden lg:block'>
                      {Cookies.Get('full_name').value}
                    </h3>
                    <KeyboardArrowDown className='mt-1' fontSize='small' />
                  </div>
                  {/* <Button variant='gradient'>Open Menu</Button> */}
                </MenuHandler>
                <MenuList>
                  <MenuItem onClick={LogoutHandle}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
