import React from 'react';
import Confirm from './Components/Atoms/Dialog/Confirm';
import Navigations from './Components/Navigations';
// import Header from './Components/Header';
// import TopSidebar from './Components/TopSidebar';

// const HeaderMemo = memo(Header)

function App() {
  return (
    <Navigations>
      {/* <TopSidebar /> */}
      <Confirm/>
    </Navigations>

  );
}

export default App;
