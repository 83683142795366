import axios from 'axios'
import { HTMLAttributes } from 'react'
import { ICookies, IRequest, ISetCookie } from '../interfaces/app.interfaces'

// export const Headers = (token: string) => {
//   return {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Credentials': 'true',
//     'Access-Control-Allow-Headers': 'content-type'
//   }
// }

export const Request = <T extends JSX.Element | unknown>({
  endpoint,
  method = 'GET',
  data
}: IRequest) => {
  let uri = process.env.REACT_APP_API_URL + '/api/v1/portal' + endpoint
  let config = {
    url: uri,
    method: method,
    headers: {
      Authorization: 'Bearer ' + Cookies.Get('access').value
    }
  }
  if (data) {
    config = Object.assign({ ...config }, { data })
  }
  return axios<T>(config)
}

export class Cookies {
  // get cookies
  static Get (keyFind: string): ICookies {
    const cookies = document.cookie.split(';')
    const access = cookies.map(v => {
      const [key, value] = v.split('=')
      if (key.trim() === keyFind) {
        return { key: key.trim(), value: value.trim() } as ICookies
      }
      return {} as ICookies
    })
    const res = access.filter(
      v => v.key !== undefined && v.key.length > 0
    )[0] as ICookies

    if (res) return res
    return {} as ICookies
  }

  // set cookies
  static Set ({ key, value, lifetime }: ISetCookie): void {
    const cookieData = key + '=' + value
    let maxAge = null
    const path = 'path=/'
    if (lifetime) {
      maxAge = 'max-age=' + lifetime
    }
    document.cookie = [cookieData, maxAge, path].flat(Infinity).join(';')
  }
  static Del (data: string[]): void {
    data.map(val => {
      document.cookie = val + '=; max-Age=-99999999;'
    })
  }
}

interface ISpinner extends HTMLAttributes<HTMLOrSVGElement> {
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export const Spinner = ({ className, size = 'sm' }: ISpinner) => {
  const setSize = (): string => {
    if (size === 'md') return 'w-6 h-6'
    if (size === 'lg') return 'w-8 h-8'
    if (size === 'xl') return 'w-10 h-10'
    return 'w-4 h-4'
  }
  return (
    <svg
      aria-hidden='true'
      role='status'
      className={`inline ${className} ${setSize()} mr-2 text-gray-200 animate-spin dark:text-gray-600`}
      viewBox='0 0 100 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
        fill='currentColor'
      />
      <path
        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
        fill='#1C64F2'
      />
    </svg>
  )
}

export const VoteIcon = () => {
  return (
    <svg
      className='w-5 h-5'
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='512.000000pt'
      height='512.000000pt'
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill='currentColor'
        stroke='none'
      >
        <path
          d='M2242 4377 l-742 -742 468 -468 467 -467 130 0 130 0 678 678 677
 677 -533 533 -532 532 -743 -743z m1053 -438 c0 -3 -163 -171 -362 -373 -334
 -340 -363 -367 -381 -355 -10 8 -108 100 -217 206 l-198 192 98 103 c54 57
 102 105 107 107 6 2 57 -43 115 -100 l105 -104 260 267 261 268 106 -102 c58
 -57 106 -105 106 -109z'
        />
        <path
          d='M760 2550 l0 -600 1800 0 1800 0 0 600 0 600 -393 0 -392 0 -225
 -225 -225 -225 248 0 248 0 -3 -147 -3 -148 -1050 0 -1050 0 -3 148 -3 147
 248 0 248 0 -225 225 -225 225 -397 0 -398 0 0 -600z'
        />
        <path d='M760 825 l0 -825 1800 0 1800 0 0 825 0 825 -1800 0 -1800 0 0 -825z' />
      </g>
    </svg>
  )
}

export const Csp = [
  { email: 'agung.arif@compnet.co.id', name: 'Agung Arif Budiman', location: 'Service Point Palu' },
  { email: '', name: 'Ahmad Jusmin', location: 'Service Point Makassar' },
  { email: '', name: 'Alfian Ruslan', location: 'Service Point Kendari' },
  { email: '', name: 'Anang Fahmi Ridlo', location: 'Service Point Semarang' },
  { email: '', name: 'Angga Apriliandi', location: 'Service Point Palangkaraya' },
  { email: '', name: 'Aziz Maulana Rosyid', location: 'Service Point Solo' },
  { email: '', name: 'Dedi Rahmad', location: 'Service Point Jayapura' },
  { email: '', name: 'Erik Oktariansa', location: 'Service Point Batam' },
  { email: '', name: 'Gede Iwan Saputra', location: 'Service Point Denpasar' },
  { email: '', name: 'Geriy Febriasyah', location: 'Service Point Medan' },
  { email: '', name: 'Haidir', location: 'Service Point Banjarmasin' },
  { email: '', name: 'Hariyanto', location: 'Service Point Ambon' },
  { email: '', name: 'Jeky', location: 'Service Point Pontianak' },
  { email: '', name: 'Lydio V. Fahik', location: 'Service Point Kupang' },
  { email: '', name: 'M. Rizal Marsaoly', location: 'Service Point Ternate' },
  { email: '', name: 'M. Taufik Nurhuda', location: 'Service Point Bandar Lampung' },
  { email: '', name: 'Muh Tajalli', location: 'Service Point Mamuju' },
  { email: '', name: 'Muhamad Arief', location: 'Service Point Jambi' },
  { email: '', name: 'Muhammad Rachmadani', location: 'Service Point Balikpapan' },
  { email: '', name: 'Nur Wahid Hendi Saputro', location: 'Service Point Manokwari' },
  { email: '', name: 'Rendra Puyo', location: 'Service Point Gorontalo' },
  { email: '', name: 'Reno Purwanto Putra', location: 'Service Point Padang' },
  { email: '', name: 'Ridha Ardian', location: 'Service Point Banda Aceh' },
  { email: '', name: 'Sahdan Mokodongan', location: 'Service Point Manado' },
  { email: '', name: 'Sofian Hadi', location: 'Service Point Mataram' },
  { email: '', name: 'Teguh Ramadhan Putra', location: 'Service Point Bandung' },
  { email: '', name: 'Veby Vabyo', location: 'Service Point Pangkalpinang' },
  { email: '', name: 'Yoga Oktasari', location: 'Service Point Bengkulu' },
  { email: '', name: 'Yoggie Al Hanif', location: 'Service Point Palembang' },
  { email: '', name: 'Zainul Arifin', location: 'Service Point Malang' }
]

export const ArraySearch = <
  T extends JSX.Element | unknown,
  U extends JSX.Element | unknown
>(
  data: T[],
  finds: U[],
  callback: (param1: T, param2: U) => boolean
) => {
  const res: T[] = []
  data.map(l => {
    const find = finds.map(s => {
      return callback(l, s)
    })
    const select = find.filter(g => g == false).length === finds.length
    if (select) res.push(l)
  })
  return res
}


export const Months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
]