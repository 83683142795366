import { selector } from 'recoil'
import { IEngineers, IReward, ITimeStamp } from '../interfaces/app.interfaces'
import { Request } from '../utils'
import {
  Engineers,
  IsVoted,
  Period,
  RewardFormPeriodAtom,
  RewardsAtom
} from './atom'
import moment from 'moment-timezone'

export const EngineersSelector = selector<IEngineers[]>({
  key: 'engineersSelector',
  get: async ({ get }) => {
    const res = await Request<IEngineers[]>({
      endpoint: '/golang_api/engineers/?spv_email=sonny.asmara@compnet.co.id'
    })
    return res.data
  }
  // set: ({ set }, newValue) => {
  //   set(Engineers, newValue)
  // }
})

export const PeriodeSelector = selector<string>({
  key: 'periodeSelector',
  get: async ({ get }) => {
    const req = await Request<ITimeStamp>({ endpoint: '/timestamp/' })
    return moment(req.data.timestamp).format('YYYY MMM')
  },
  set: ({ set }, newVal) => {
    set(Period, newVal)
  }
})

export const RewardSelector = selector<IReward[]>({
  key: 'rewardSelectorKey',
  get: async ({ get }) => {
    const params = get(RewardFormPeriodAtom)
    const res = await Request<IReward[]>({
      endpoint: '/employee/rewards/?period=' + params
    })
    return res.data
  },
  set: ({ set }, newValue) => {
    set(RewardsAtom, newValue)
  }
})

export const IsVotedSelector = selector<IReward[]>({
  key: 'isVotedKey',
  get: async ({ get }) => {
    const res = await Request<IReward[]>({
      endpoint: '/employee/voted/'
    })
    return res.data
  },
  set: ({ set }, newValue) => {
    set(IsVoted, newValue)
  }
})
