import { MilitaryTech } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import Contents from '../../Components/Contents'
import { IEngineers } from '../../interfaces/app.interfaces'
import { EngineersSelector } from '../../recoil/selectors'
import { ArraySearch, Spinner } from '../../utils'

const ex: string[] = String(process.env.REACT_APP_EX_ENGINEER).split(';')
const ServicePoin = () => {
  const [tableEngineers, setTableEngineers] = useState<IEngineers[]>([])
  const engineers = useRecoilValueLoadable(EngineersSelector)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (engineers.state === 'hasValue') {
      const filter = (a: IEngineers, b: string) => a.engineer_email === b
      const csps = ArraySearch<IEngineers, string>(
        engineers.contents,
        ex,
        filter
      )
      csps.sort((a, b) => (a.engineer_name < b.engineer_name ? -1 : 1))
      setTableEngineers(csps)
      setLoading(false)
    }
  }, [engineers])

  return (
    <Contents className='mb-40'>
      <h4 className='text-xl mb-5'>SERVICE POIN ENGINEERS</h4>
      <div className='w-full mb-28'>
        <div className='card-table'>
          {loading ? (
            <div className='w-full p-3'>
              <Spinner />
            </div>
          ) : (
            <table className='w-full mytable'>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Engineer</th>
                  <th>Email</th>
                  <th>Site</th>
                  {/* <th>Reward</th> */}
                </tr>
              </thead>
              <tbody>
                {tableEngineers
                  .filter(d => d.engineer_location?.length > 0)
                  .map((val, i) => (
                    <tr
                      key={i}
                      className={`${
                        i % 2 === 0
                          ? 'bg-gray-100 hover:bg-gray-200'
                          : 'hover:bg-gray-100'
                      } p-1`}
                    >
                      <td>{i + 1}</td>
                      <td>{val.engineer_name}</td>
                      <td>{val.engineer_email}</td>
                      <td>{val.engineer_location}</td>
                      {/* <td>
                        <div className='text-right'>
                          <MilitaryTech className='text-yellow-900' />
                        </div>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Contents>
  )
}

export default ServicePoin
