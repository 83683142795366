import { Clear, KeyboardArrowDown } from '@mui/icons-material'
import React, { ChangeEvent, useEffect, useState, FC } from 'react'

export interface IOptions {
  label: string
  value: any
}
interface Props {
  value: IOptions
  dataSet: IOptions[]
  label?: string
  name?: string
  bodyClass?: string | undefined
  containerClass?: string | undefined
  labelLength?: number
  searchBox?:boolean
  innerRef?:React.RefCallback<HTMLDivElement>
  onChange?: (val: IOptions) => void
  onChangeInput?: (val: string) => void
  onClickButtonClear?:  () => void
}

const defaultProps: Props = {
  value: { value: '', label: '' },
  dataSet: [],
  searchBox: true,
  labelLength: 0,
  name: String(Math.random()).substring(2, 7),
  label: "Select Items",
  onChange: (val: IOptions) => { },
}


const SelectCustomComponent: FC<Props> = (props) => {
  // console.log("SelectCustom rendered")
  const [dropDownListOnShow, setDropDownListOnShow] = useState(false)
  const [data, setData] = useState<IOptions[]>(props.dataSet)
  const itemsRef = React.createRef<HTMLDivElement>()
  const scItemsRef = React.createRef<HTMLDivElement>()
  const [itemSelectedValue, setItemSelectedValue] = useState<IOptions>(
    props.value,
  )

  const documentClickEvent = (e: any) => {
    const className = e.target.className
    if (e.target.tagName !== 'svg' && e.target.tagName !== 'path') {
      const res = className.split(' ').map((val: string) => {
        const pattern = '\\b' + props.name + '\\b'
        const sc = val.match(new RegExp(pattern, 'g'))?.length
        if (sc !== undefined) {
          return true
        } else {
          return false
        }
      })
      //jika yg di click adalah di luar elemen select custom
      if (!res.filter((v: boolean) => v === true)[0]) {
        setDropDownListOnShow(false)
      }
    }
  }
  const isClearIconClicked = (e: any) => {
    const isIconItemClick = (e: any) => {
      if (e.target.tagName === 'path') {
        return Array.from(e.target.parentElement.classList).filter(
          (f) => f === 'icon-clear-item',
        )
      } else if (e.target.tagName === 'svg') {
        return Array.from(e.target.classList).filter(
          (f) => f === 'icon-clear-item',
        )
      } else {
        return []
      }
    }
    if (isIconItemClick(e).length > 0) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    document.addEventListener('click', (e) => documentClickEvent(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const body = document.querySelector('body')
    body?.setAttribute('style', 'height:100vh')
    if (itemSelectedValue.value !== '') {
      scItemsRef.current?.scrollTo(0, itemsRef.current?.offsetTop! - 120)
    }
    // console.log("=> itemSelectedValue ", itemSelectedValue)
    setData(props.dataSet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSelectedValue, dropDownListOnShow])

  useEffect(() => {
    if(props.value.value === ''){
      setItemSelectedValue(defaultProps.value)
    }else{
      setItemSelectedValue(props.value)
    }
  }, [props.value])
  const dorpDownToggle = (e: any) => {
    setDropDownListOnShow(!dropDownListOnShow)
    setTimeout(() => {
      // e.target?.nextSibling?.firstChild?.firstChild?.focus()
      document.querySelector<HTMLInputElement>("#inputSearch" + props.name)?.focus()
    }, 200)
  }

  const containerOnClickHandle = (e: any) => {
    if (!isClearIconClicked(e)) dorpDownToggle(e)
  }
  const inputChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChangeInput) {
      props.onChangeInput(e.target.value)
      setData(props.dataSet)
    } else {
      const filter = props.dataSet.filter((v) =>
        v.label.toLowerCase().includes(e.target.value.toLowerCase()),
      )
      setData(filter)
    }
  }
  const itemSelected = (val: IOptions) => {
    if (props.onChange) props.onChange(val)
    setItemSelectedValue(val)
    setTimeout(() => setDropDownListOnShow(false), 100)
  }
  const clearItemSelected = () => {
    setItemSelectedValue(defaultProps.value)
    props.onClickButtonClear && props.onClickButtonClear()
  }
  const setSubStr = (item: string) => {
    const len = item.length
    if (props.labelLength && props.labelLength > 0) {
      const until_count = props.labelLength
      if (len > until_count) {
        return item.substring(0, until_count) + "..."
      }
      return item
    }
    return item
  }
  return (
    <div className={`sc-container w-full`} ref={props.innerRef}>
      <div className={`sc-wrapper-${props.name}`}>
        <div
          onClick={(e) => containerOnClickHandle(e)}
          className={`${dropDownListOnShow && 'sc-select-container-focus-' + props.name
            } sc-select-container ${props.containerClass}`}
        >
          {/* for label */}
          <div
            className={`${itemSelectedValue?.label !== '' || dropDownListOnShow
              ? '-translate-y-5 text-[11px]'
              : 'translate-y-0'
              } ${!dropDownListOnShow && 'text-blue-grey-500'
              } duration-150 absolute  transform sc-current-item-selected-label-${props.name} sc-item-label`}
          >
            {props.label}
          </div>
          {/* for value selected */}
          <div className={`sc-current-item-selected-${props.name} -translate-y-0`}>
            {itemSelectedValue?.label}
          </div>
          <div className="flex">
            {itemSelectedValue.value && (
              <Clear
                onClick={() => clearItemSelected()}
                color="error"
                fontSize="small"
                titleAccess="clear"
                className="icon-clear-item"
              />
            )}
            <div
              className={`${dropDownListOnShow && 'rotate-180'
                } sc-icon-class-wrapper-${props.name} transition transform duration-500`}
            >
              <KeyboardArrowDown
                className={`sc-icon-class-item-${props.name} !text-xl !text-bule-grey-600`}
                fontSize="medium"
              />
            </div>
          </div>
        </div>
        {dropDownListOnShow && (
          <div className={`sc-body-${props.name} ${props.bodyClass}`}>
           {props.searchBox && <div className={`sc-input-wrapper-${props.name}`}>
              <input
                placeholder="Search"
                id={`inputSearch${props.name}`}
                onChange={(e) => inputChangeHandle(e)}
                className={`sc-input-${props.name}`}
              />
              {/* <p>dataLength: {data.length}</p> */}
            </div>} 
            <div ref={scItemsRef} className={`sc-items-wrapper-${props.name}`}>
              {data.map((val, i) => (
                <div
                  title={val.label}
                  key={i}
                  ref={itemSelectedValue.value === val.value ? itemsRef! : null}
                  onClick={() => itemSelected(val)}
                  className={`${itemSelectedValue.value === val.value && 'sc-item-selected'
                    } sc-item`}
                >
                  {setSubStr(val.label)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
SelectCustomComponent.defaultProps = defaultProps
const SelectCustom = React.memo(SelectCustomComponent) as typeof SelectCustomComponent
export default SelectCustom
