import React, { memo, useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Vote from '../pages/Vote'
import Login from '../pages/SignIn'
import Rewards from '../pages/Rewards'
import ServicePoin from '../pages/ServicePoin'
import { Request, Spinner } from '../utils'
import Header from './Header'
import Footer from './Footer'
import TopSidebar from './TopSidebar'

const DashboardMemo = memo(Dashboard)
const VoteMemo = memo(Vote)
const ServicePoinMemo = memo(ServicePoin)
const RewardsMemo = memo(Rewards)
const LoginMemo = memo(Login)
const HeaderMemo = memo(Header)
const FooterMemo = memo(Footer)

type Props = {
  children: React.ReactNode | JSX.Element
}
const Navigations = ({ children }: Props) => {
  return (
    <div>
      {children}
      <Routes>
        <Route path='/' element={<AuthPage />}>
          <Route index element={<DashboardMemo />} />
          <Route path='/voting' element={<VoteMemo />} />
          <Route path='/service-poin' element={<ServicePoinMemo />} />
          <Route path='/rewards' element={<RewardsMemo />} />
        </Route>
        <Route path='auth' element={<LoginMemo />} />
      </Routes>
    </div>
  )
}

export default Navigations

const AuthPage = () => {
  const [loading, setLoading] = useState(true)
  const [isLogin, setIsLogin] = useState(false)
  useEffect(() => {
    Request({ endpoint: '/authentication/login_check/' })
      .then(r => {
        setIsLogin(true)
      })
      .catch(err => {
        console.log(err.response)
      })
      .finally(() => setLoading(false))
  }, [])
  return (
    <React.Fragment>
      {loading && (
        <div className='h-full w-full bg-gradient-to-r from-gray-200 to-blue-200 z-50 absolute top-0 right-0 bottom-0 left-0 grid place-items-center'>
          <div>
            <Spinner size='lg' />
          </div>
        </div>
      )}
      {!loading && isLogin && (
        <React.Fragment>
          <HeaderMemo />
          <TopSidebar/>
          <Outlet />
          <FooterMemo />
        </React.Fragment>
      )}
      {!loading && !isLogin && <Navigate to='/auth' />}
    </React.Fragment>
  )
}
