import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react'
import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
// import { IDialog } from '../../../interfaces/app.interface'
import { DialogConfirmAtom } from '../../../recoil/atom'


// export const defaultPropsDialogConfirm: IDialog = {
//     title: "Delete Confirm",
//     noLabel: "Tidak",
//     yesLabel: "Ya",
//     open: false,
//     size: "xs"
// }

const Confirm = () => {
    const { onCancel, onConfirm, initState } = useConfirmDlg()
    return (
        <React.Fragment>
            <Dialog size={initState.size} open={initState.open} handler={onCancel}>
                <DialogHeader>{initState.title}</DialogHeader>
                <DialogBody divider>
                    {initState.body}
                </DialogBody>
                <DialogFooter>
                    <Button size='sm' onClick={onCancel} color='red'>{initState.noLabel}</Button>
                    <Button variant='text' size='sm' className='mr-1' color='green' onClick={onConfirm}>{initState.yesLabel}</Button>
                </DialogFooter>
            </Dialog>
        </React.Fragment>
    )
}
// Confirm.defaultProps = defaultPropsDialogConfirm
export default Confirm

class Deferred {
    private promise
    private reject: any
    private resolve: any
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

interface IConfirm {
    title?: string | JSX.Element | React.ReactNode
    message?: string | JSX.Element | React.ReactNode
}



let resolvePromise: any
export function useConfirmDlg() {
    const [initState, setDialogConfirm] = useRecoilState(DialogConfirmAtom)

    const closeDialog = () => {
        setDialogConfirm({ ...initState, open: false })
    }
    const onCancel = () => {
        closeDialog()
    }
    const onConfirm = () => {
        closeDialog()
        resolvePromise(true)
    }
    const confirm = (props: IConfirm | string ) => {
        console.log(typeof props)
        if (typeof props === 'object') {
            setDialogConfirm({ ...initState, title: props.title, body: props.message, open: true })
        } else {
            setDialogConfirm({ ...initState, title: "Confirm", body: props, open: true })
        }
        return new Promise((resolve) => {
            resolvePromise = resolve
        })
    }
    return { confirm, onCancel, onConfirm, initState }
}
