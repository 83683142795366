import React, { HTMLAttributes, useEffect, useState } from 'react'
import {
  Home,
  FormatListBulleted,
  Group,
  EmojiEvents
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { ContentInterface } from '../interfaces/app.interfaces'
import { VoteIcon } from '../utils'
import { useRecoilState } from 'recoil'
import {MenuActive} from '../recoil/atom'
// import SvgIcon from '@mui/material/SvgIcon';

const TopSidebar = () => {
  const [menuActive, setMenuActive] = useRecoilState(MenuActive)
  useEffect(() => {
    // console.log({ menuActive })
  }, [menuActive])
  const sidebarMenuClick = (path: string) => {
    setMenuActive(path)
  }
  return (
    <div className='top-sidebar-container flex justify-end bg-blue-400'>
      <div className='flex items-center h-10 w-11/12'>
        <MainMenu>
          <MenuItem
            onClick={() => sidebarMenuClick('/')}
            className={menuActive === '/' ? 'active' : ''}
            to='/'
            iconMenu={<Home />}
            title='Dashboard'
          />
          <MenuItem
            onClick={() => sidebarMenuClick('/voting')}
            to='voting'
            iconMenu={<VoteIcon />}
            title='Vote'
            className={menuActive === '/voting' ? 'active' : ''}
          />
          <MenuItem
            onClick={() => sidebarMenuClick('/service-poin')}
            to='service-poin'
            iconMenu={<Group />}
            title='Service Poin'
            className={menuActive === '/service-poin' ? 'active' : ''}
          />
          <MenuItem
            className={menuActive === '/rewards' ? 'active' : ''}
            onClick={() => sidebarMenuClick('/rewards')}
            to='rewards'
            iconMenu={<EmojiEvents />}
            title='Rewards'
          />
        </MainMenu>
      </div>
    </div>
  )
}

export default TopSidebar

export const MainMenu = ({ children }: ContentInterface) => {
  return <ul className='flex'>{children}</ul>
}

interface ISidebar extends HTMLAttributes<HTMLAnchorElement> {
  to: string
  title: string
  iconMenu: JSX.Element | React.ReactNode
}

export const MenuItem = ({
  to,
  title,
  iconMenu,
  className,
  ...props
}: ISidebar) => {
  return (
    <li className='h-10'>
      <Link to={to} className={`sidebar-menu ${className}`} {...props}>
        {iconMenu}
        <h3>{title}</h3>
      </Link>
    </li>
  )
}
