import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  Typography
} from '@material-tailwind/react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  ILogin,
  ILoginRespon,
  IMS365AccessToken
} from '../../interfaces/app.interfaces'
import axios from 'axios'
import { Cookies, Spinner } from '../../utils'
import { UserAgentApplication } from 'msal'

const LoginRequest = (config: {
  endpoint: string
  method: 'POST' | 'DELETE' | 'PUT' | 'GET'
  data?: {} | [] | number | string | undefined
}) => {
  let uri = process.env.REACT_APP_API_URL + config.endpoint
  return axios<ILoginRespon>({
    url: uri,
    method: config.method,
    data: config.data
  })
}

const Login = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<Boolean>(false)
  const [loadingOffice365, setLoadingOffice365] = useState<Boolean>(false)
  const [loginStatus, setLoginStatus] = useState<Boolean | undefined>(undefined)
  const { handleSubmit, register, setFocus } = useForm<ILogin>()
  const accessScope = {
    scopes: ['user.read', 'mail.send']
  }
  const msal = new UserAgentApplication({
    auth: {
      clientId: '70b35f05-942c-46ac-a529-cc0c5882e313',
      redirectUri: process.env.REACT_APP_BASEURL
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  })

  useEffect(() => {
    setFocus('username')
  })

  const setCookieLogin  = (r: ILoginRespon) => {
    Cookies.Set({
      key: 'access',
      value: r.access,
      lifetime: r.lifetime
    })
    Cookies.Set({
      key: 'email',
      value: r.email,
      lifetime: r.lifetime
    })
    Cookies.Set({
      key: 'full_name',
      value: r.full_name,
      lifetime: r.lifetime
    })
    setLoginStatus(true)
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_BASEURL!
      console.log("goto dashboard page")
    }, 500)
    // setTimeout(() => navigate('/'), 500)
  }

  const onLoginSubmit: SubmitHandler<ILogin> = data => {
    console.log('login submit...')
    setLoading(true)
    LoginRequest({
      endpoint: '/api/v1/portal/authentication/',
      method: 'POST',
      data
    })
      .then(r => {
        setCookieLogin(r.data)
      })
      .catch(err => {
        console.log(err)
        setLoginStatus(false)
      })
      .finally(() => setLoading(false))
  }

  const loginStatusText = (text: string, status: 'green' | 'red' | 'blue') => {
    return (
      <Typography
        variant='small'
        color={status}
        className='mt-2 font-bold flex justify-center'
      >
        {/* Sign In successfully! */}
        {text}
      </Typography>
    )
  }
  const fetchLoginMs365 = (token: string) => {
    const msapi =
      '/api/v1/portal/authentication/microsoft_authentication_token/'
    return axios<ILoginRespon>({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + msapi,
      data: { tokenAccess: token }
    })
  }

  const LoginOffice365Handle = () => {
    setLoadingOffice365(true)
    msal
      .loginPopup(accessScope)
      .then(() => {
        getAccount().then(res => {
          console.log(res.accessToken)
          fetchLoginMs365(res.accessToken).then(r => {
            setCookieLogin(r.data)
          }).finally(() => setLoadingOffice365(false))
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAccount = () => {
    return new Promise<IMS365AccessToken>((resolve, reject) => {
      if (msal.getAccount()) {
        msal
          .acquireTokenSilent(accessScope)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            if (err.name === 'InteractionRequiredAuthError') {
              return msal
                .acquireTokenPopup(accessScope)
                .then(response => {
                  resolve(response)
                })
                .catch(err => {
                  reject(err)
                })
            }
          })
      } else {
        reject('Butuh login dulu dari portalDB')
      }
    })
  }
  return (
    <div className='grid place-items-center h-screen bg-blue-gray-200'>
      <Card className='w-96'>
        <CardBody className='flex flex-col gap-4'>
          <div className='mb-4 text-center'>
            <Typography variant='h4'>USER LOGIN</Typography>
          </div>
          <form
            id='login'
            name='login'
            onSubmit={handleSubmit(onLoginSubmit)}
            className='space-y-4'
          >
            <Input
              {...register('username')}
              // onChange={() => setLoginStatus(undefined)}
              label='username'
              size='md'
            />
            <Input
              type='password'
              {...register('password')}
              // onChange={() => setLoginStatus(undefined)}
              label='Password'
              size='md'
            />
          </form>
        </CardBody>
        <CardFooter className='pt-0'>
          <div className='space-y-2'>
            <Button type='submit' form='login' variant='gradient' fullWidth>
              {loading && <Spinner />}
              Sign In
            </Button>
            <Button
              onClick={LoginOffice365Handle}
              type='submit'
              variant='gradient'
              color='indigo'
              fullWidth
            >
              {loadingOffice365 && <Spinner />}
              Sign In with office 365
            </Button>
          </div>
          {/* <Typography variant='small' className='mt-6 flex justify-center'>
            Are you forget an password?
            <Typography
              as='a'
              href='#signup'
              variant='small'
              color='blue'
              className='ml-1 font-bold'
            >
              Reset Password
            </Typography>
          </Typography> */}
          {loginStatus && loginStatusText('Sign In successfully!', 'green')}
          {loginStatus === false &&
            loginStatusText('Sign In unseuccess!', 'red')}
        </CardFooter>
      </Card>
    </div>
  )
}
export default Login
