import React, { useEffect, useState } from 'react'
import Contents from '../../Components/Contents'
import { IEngineers, IReward } from '../../interfaces/app.interfaces'
import { EngineersSelector, RewardSelector } from '../../recoil/selectors'
import { ArraySearch, Cookies, Months, Request, Spinner } from '../../utils'
import { createRoot } from 'react-dom/client'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  Cookie,
  Info,
  MilitaryTech,
  Refresh,
  WidthNormalRounded
} from '@mui/icons-material'
import moment from 'moment-timezone'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Option,
  Select
} from '@material-tailwind/react'
import { RewardFormPeriodAtom } from '../../recoil/atom'

interface IResReward {
  name: string
  score: number
  email: string
  site: string
}
const Rewards = () => {
  const RewardsData = useRecoilValueLoadable(RewardSelector)
  const RewardsDataRefresh = useRecoilRefresher_UNSTABLE(RewardSelector)
  const engineers = useRecoilValueLoadable(EngineersSelector)
  const [resReward, setResReward] = useState<IResReward[]>([])
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const tableRef = React.createRef<HTMLTableElement>()
  const [oldRowSelect, setOldRowSelect] = useState<HTMLTableElement | null>()
  const [oldRowIndex, setOldRowIndex] = useState<number | undefined>(undefined)
  const [badRequest, setBadRequest] = useState(false)
  const setRewardFormPeriod = useSetRecoilState(RewardFormPeriodAtom)
  const dateReward = new Date()
  dateReward.setMonth(dateReward.getMonth() - 1)
  const [Period, setPeriod] = useState(moment(dateReward).format('MMMM YYYY'))
  const [periodes, setPeriodes] = useState<string[]>([])
  //   const [open, setOpen] = useState(1)

  //   const handleOpen = (value: number) => {
  //     setOpen(open === value ? 0 : value)
  //   }
  useEffect(() => {
    const date = new Date()
    const startYear = 2022
    const _periodTmp: string[] = []
    for (let i = startYear; i <= date.getFullYear(); i++) {
      for (let m = 0; m <= Months.length - 1; m++) {
        _periodTmp.push(Months[m] + ' ' + i)
      }
    }
    setPeriodes(_periodTmp)

    if (RewardsData.state === 'hasValue') {
      if (engineers.state === 'hasValue') {
        // const csps = Csp.map(csp => {
        //   return engineers.contents.filter(h => h.engineer_name === csp.name)[0]
        // })
        const exs = String(process.env.REACT_APP_EX_ENGINEER).split(';')
        const csps = ArraySearch<IEngineers, string>(
          engineers.contents,
          exs,
          (a, b) => a.engineer_email === b
        )
        csps.sort((a, b) => (a.engineer_name < b.engineer_name ? -1 : 1))
        const results = csps
          .filter(v => v)
          .map(value => {
            const score = RewardsData.contents
              .filter(
                (q: any) => q.fields.engineer_email === value.engineer_email
              )
              .reduce((a: number, b) => a + parseInt(b.fields.score), 0)
            return {
              name: value.engineer_name,
              score,
              email: value.engineer_email,
              site: value.engineer_location
            }
          })
        setResReward(results.sort((a, b) => (a.score > b.score ? -1 : 1)))
        setLoading(false)
      }
    } else if (RewardsData.state === 'hasError') {
      if (RewardsData.contents.code === 'ERR_BAD_REQUEST') {
        setBadRequest(true)
      }
    }
    console.log('=> loading2', loading2)
  }, [RewardsData, engineers, loading2])

  const withName = (v: IReward): string => {
    if (engineers.state === 'hasValue') {
      const [engineer] = engineers.contents.filter(
        b => b.engineer_email == v.fields.vote_by
      )
      // console.log(engineer, " => ", v.fields.vote_by)
      if (engineer !== undefined) return engineer.engineer_name
    }
    return v.fields.vote_by
  }

  const trOnClick = (rowIndex: number, email: string) => {
    if (oldRowIndex !== undefined) oldRowSelect?.deleteRow(oldRowIndex)
    console.log({ oldRowIndex, rowIndex: rowIndex + 2 })
    if (oldRowIndex === rowIndex + 2) {
      setOldRowIndex(undefined)
      return false
    }
    console.log('new row')
    const row = tableRef.current
    setOldRowSelect(row)
    const inserRow = row?.insertRow(rowIndex + 2)
    setOldRowIndex(rowIndex + 2)
    let cell1 = inserRow?.insertCell(0)
    cell1?.setAttribute('colspan', '5')
    const elem = document.createElement('div')
    elem.setAttribute('class', 'p-8 relative')
    cell1?.appendChild(elem)
    createRoot(elem).render(<DetailVoting period={Period} email={email} withName={withName} />)
  }

  const refreshData = () => {
    setLoading(true)
    RewardsDataRefresh()
  }

  return (
    <Contents className='mb-40'>
      <h4 className='text-xl mb-5 content-title'>
        REWARDS PERIOD {Period.toUpperCase()}
      </h4>
      {badRequest ? (
        <Alert color='red' className='w-fit'>
          <div className='flex justify-center space-x-2'>
            <Info color='inherit' />
            <span>
              Sorry <b>{Cookies.Get('full_name').value}</b>, this page not
              allowed access!
            </span>
          </div>
        </Alert>
      ) : (
        <div className='w-full'>
          <div className='mb-2 flex space-x-2'>
            <div>
              {periodes.length > 0 && (
                <Select onChange={(e) => {
                  const d: any = e 
                  setPeriod(String(d))
                  setRewardFormPeriod(String(d))
                }} label='Select Periode' value={Period.toLowerCase()}>
                  {periodes.map((val, i) => (
                    <Option key={i} value={val}>{val.toUpperCase()}</Option>
                  ))}
                </Select>
              )}
            </div>
            <Button
              onClick={() => refreshData()}
              className='px-2 py-1 flex justify-center items-center'
              variant='gradient'
              color='green'
              size='sm'
            >
              <Refresh />
              Refresh
            </Button>
          </div>
          <div className='card-table'>
            {loading ? (
              <div className='w-full p-3'>
                <Spinner />
              </div>
            ) : (
              <div>
                <table ref={tableRef} className='w-full mytable'>
                  <thead>
                    <tr>
                      <th>No.</th>

                      <th>&ensp;&ensp;&ensp;&ensp;Engineers</th>
                      <th>Email</th>
                      <th>Site</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resReward.map((v, i) => (
                      <tr
                        onClick={e => trOnClick(i, v.email)}
                        key={i}
                        className={`${
                          i % 2 === 0
                            ? 'bg-gray-100 hover:bg-gray-200'
                            : 'hover:bg-gray-100'
                        } p-1 cursor-pointer`}
                      >
                        <td>{i + 1}</td>

                        <td>
                          {i === 0 ? (
                            <MilitaryTech className='text-yellow-900' />
                          ) : (
                            <span>&ensp;&ensp;&ensp;</span>
                          )}{' '}
                          {v.name}
                        </td>
                        <td>{v.email}</td>
                        <td>{v.site}</td>
                        <td className='font-bold'>{v.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}

      {/* <Card className='w-[40%]'>
          <CardBody>
            2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut porro
            doloremque eaque, saepe, corrupti odit magni tempora sapiente
            doloribus quas numquam maxime, rerum totam in sint dolores libero
            eligendi officia?
          </CardBody>
        </Card> */}
    </Contents>
  )
}

export default Rewards

export const DetailVoting = ({
  email,
  period,
  withName
}: {
  email: string
  period: string
  withName: (e: IReward) => string
}) => {
  const [loading2, setLoading2] = useState(true)
  const [dataVote, setDataVote] = useState<IReward[]>([])

  useEffect(() => {
    Request<IReward[]>({
      endpoint: '/employee/voting_lists/?email=' + email + '&period=' + period
    }).then(r => {
      setDataVote(r.data)
      setLoading2(false)
    })
  }, [])

  return (
    <div>
      <div className='card-table w-fit shadow-lg z-10'>
        <table className='mytable'>
          <thead>
            <tr>
              <th>No</th>
              <th>VoteBy</th>
              <th>Period</th>
              <th>Vote Date</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {loading2 ? (
              <tr>
                <td colSpan={5}>
                  <Spinner />
                </td>
              </tr>
            ) : dataVote.length > 0 ? (
              dataVote.map((v, i) => (
                <tr
                  key={i}
                  className={`${i % 2 === 0 ? 'bg-green-50' : ''} row-dt`}
                >
                  <td>{i + 1}</td>
                  <td>{withName(v)}</td>
                  <td>{v.fields.period}</td>
                  <td>{moment(v.fields.date).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td>{v.fields.score}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>no match</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='absolute -z-0 -top-1 left-1 border-gray-300 border-l border-b h-[50%] w-[28px]'></div>
    </div>
  )
}
