import React, { FC } from 'react'
// import { useRecoilValue } from 'recoil'
import { ContentInterface } from '../interfaces/app.interfaces'

const Contents: FC<ContentInterface> = ({ children, className , ...props }) => {
  return (
      <div {...props} className={`contents-container ${className && className}`}>
         <div className='w-11/12 pr-14'>
          {children}
        </div>
      </div>
  )
}

export default Contents
